import React from 'react';
import learnmorearrow from "../../assets/img/learnmore-arrow.svg";
import { Button } from "react-bootstrap";
import { Tooltip as ReactTooltip } from 'react-tooltip'


function Card(props) {
    return (
        <div className="customCard">
              <div className="d-flex align-items-center">
                <img alt="icon" src={props.icon} />
                <h5>{props.title}</h5>
              </div>
              <p>
                {props.content}
              </p>
              <Button
                href="#"
                variant=""
                // onClick={() => onClickOpenVacancy(id)}
                data-tooltip-id="my-tooltip-1"
              >Learn More <img alt="icon" src={learnmorearrow} /></Button>
              <ReactTooltip
                id="my-tooltip-1"
                place="right"
                className='customTooltip'
                content="Coming Soon"
                style={{
                  backgroundColor: '#004ac3',
                  padding: '3px 7px',
                  borderRadius: '5px',
                  fontSize: '14px',
                }}
              />
              
            </div>
    );
}

export default Card;