import React, { useEffect, useState } from "react";
import superpower from "../../assets/img/superpower.png";
import counterfiet_big from "../../assets/img/counterfiet_big.svg";
import custumer_trust from "../../assets/img/custumer_trust.svg";
import hussale_free from "../../assets/img/hussale_free.svg";
import tickCircle from "../../assets/img/tick-circle.svg";
import realtime_tracking from "../../assets/img/realtime_tracking.svg";
import repoting from "../../assets/img/repoting.svg";
import customization from "../../assets/img/customization.svg";
import data_integration from "../../assets/img/data_integration.svg";
import client_automated from "../../assets/img/client_automated.svg";
import automated_detection from "../../assets/img/automated_detection.svg";
import custumer_verification from "../../assets/img/custumer_verification.svg";
import journey_line from "../../assets/img/journey_line.svg";
import journey_1 from "../../assets/img/journey_1.svg";
import journey_2 from "../../assets/img/journey_2.svg";
import journey_3 from "../../assets/img/journey_3.svg";
import journey_4 from "../../assets/img/journey_4.svg";
import journey_5 from "../../assets/img/journey_5.svg";
import journey_6 from "../../assets/img/journey_6.svg";
import linkedin_2 from "../../assets/img/linkedin_2.svg";
import Facebook_2 from "../../assets/img/Facebook_2.svg";
import Instagram_2 from "../../assets/img/Instagram_2.svg";
import twitter_2 from "../../assets/img/twitter_2.svg";
import counterfiet_2 from "../../assets/img/counterfiet_2.svg";
import years from "../../assets/img/years.svg";
import client_count from "../../assets/img/client_count.svg";
import user_count from "../../assets/img/user_count.svg";
import { Button } from "react-bootstrap";
import Ripples from "react-ripples";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import rating from "../../assets/img/rate-stars.svg";
import aboutushero from "../../assets/img/aboutus.svg";
import { ColorRing } from "react-loader-spinner";
import {
  getAboutusProfessionalsAsync,
  getAboutProfessionalsResponse,
  getCountAsync,
  getCountResponse,
} from "../../service/slices/aboutUsSlice";
import CountUp, { useCountUp } from 'react-countup';

function AboutUs(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [getCountData, setGetCountData] = useState({});
  const [getProfessionalsData, setGetProfessionalsData] = useState([]);
  const dispatch = useDispatch();
  const _getCountResponse = useSelector(
    (state) => state?.aboutus?.getCountResponse
  );
  const _getAboutProfessionalsResponse = useSelector(
    (state) => state?.aboutus?.getAboutProfessionalsResponse
  );

  useEffect(() => {
    // setIsLoading(true);
    setIsLoadingCount(true);
    dispatch(getCountAsync());
    dispatch(getAboutusProfessionalsAsync());
  }, []);

  useEffect(() => {
    if (_getCountResponse?.response?.status == 200) {
      setIsLoadingCount(_getCountResponse?.isLoading);
      setGetCountData(_getCountResponse?.response?.data);
    }
  }, [_getCountResponse]);

  useEffect(() => {
    if (_getAboutProfessionalsResponse?.response?.status == 200) {
      setIsLoading(_getAboutProfessionalsResponse?.isLoading);
      setGetProfessionalsData(_getAboutProfessionalsResponse?.response?.data);
    }
  }, [_getAboutProfessionalsResponse]);

  console.log(_getAboutProfessionalsResponse, "::::");

  useCountUp({
    ref: 'counter',
    // enableScrollSpy: true,
    scrollSpyOnce: true,
    scrollSpyDelay: 1500,
    duration: 5
  });
  return (
    <>
    <section className="herosection aboutus">
      <div className="container">
        <div className="row">
          {/* animate__animated animate__slideInLeft */}
          <div className="col-md-6">
            <div 


            className="animateHeroContent"


            >
              <h1>
              Get <span>Clarity</span> On <br /> Our <span>Company</span>
              </h1>
              <p>
              Delve into Clarity Codes' history and <br/>learn how we pioneer change in the<br/> industry.
              </p>
              <Ripples color="#ffffff47" during={1200}>
                <Link
                  to={process.env.REACT_APP_REGISTRATION_URL}
                  target="_blank"
                >
                  <Button variant="" className="subscribeBtn">
                  Redeem Now!
                  </Button>
                </Link>
              </Ripples>

              <div className="rating">
                <p>Outstanding Reviews from Customers Worldwide</p>
                <div>
                  <img alt="icon" src={rating} />
                  <h2>
                    4.9 / <span>5.0</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="position-relative h-100 d-flex justify-content-end animateHeroContentaboutimg">
              <div className="bg-layer">
                {/* <LazyLoadImage
                  src={phone}
                  className="phoneFrame animate__animated animate__slideInUp"
                />
                <LazyLoadImage
                  src={tab}
                  className="tabFrame animate__animated animate__slideInRight"
                /> */}
                <img alt="" className="" src={aboutushero} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <section className="aboutus_wrapper" style={{paddingTop: '96px'}}>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className="heading wow fadeIn"
                data-wow-duration="1s"
                style={{ marginBottom: "20px" }}
              >
                Introducing <span>Clarity Codes</span>
              </h2>
            </div>
            <div
              className="col-md-10 text-center offset-0 offset-md-1"
              style={{ marginBottom: "12px" }}
            >
              <p className="head_para  wow fadeIn" data-wow-duration="1s">
              Clarity Codes is a cutting-edge anti-counterfeiting solution designed specifically for hair and beauty businesses worldwide. With our technology, companies can safeguard their brand and protect customers from harmful <br /> counterfeit products.
              </p>
              <div className="tags_ui justify-content-center" style={{marginTop: "44px", marginBottom: "28px"}}>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".10s"
                  >
                    <img alt="icon" src={tickCircle} /> Protect your brand’s reputation
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".10s"
                  >
                    <img alt="icon" src={tickCircle} /> Ensure customer safety
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".20s"
                  >
                    <img alt="icon" src={tickCircle} /> Efficient and effective solution
                  </span>
                </div>
            </div>
            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".70s"
                src={counterfiet_big}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet ourmission wow fadeInRight"
                data-wow-duration=".70s"
                style={{paddingTop: "202px"}}
              >
                <h3 className="sub_heading mb-3">
                Our <span>Mission</span>{" "}
                </h3>
                <p className="sub_para">
                Our mission is to empower businesses in the hair and beauty industries to protect their brand and customers from counterfeit cosmetics.
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "64px" }}>
            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-2 order-lg-1"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet"
                // style={{ paddingTop: "81px" }}
                style={{paddingTop: "188px"}}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  Our <span>Vision</span>
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                 We envision Clarity Codes revolutionizing the way companies and customers prevent and detect counterfeit products, contributing to the reduction of harmful counterfeit cosmetics.
                </p>
                {/* <div className="tags_ui">
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".10s"
                  >
                    <img alt="icon" src={tickCircle} /> Trust Seals and Logos
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".10s"
                  >
                    <img alt="icon" src={tickCircle} /> Guarantees and Warranties
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".20s"
                  >
                    <img alt="icon" src={tickCircle} /> Responsive Customer Support
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".20s"
                  >
                    <img alt="icon" src={tickCircle} /> Regular Updates
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".30s"
                  >
                    <img alt="icon" src={tickCircle} /> Product Verification
                  </span>
                </div> */}
              </div>
            </div>

            

            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0 order-1 order-lg-2"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInRight"
                data-wow-duration=".70s"
                src={custumer_trust}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "64px" }}>
            
          <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".70s"
                src={counterfiet_big}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet ourmission wow fadeInRight"
                data-wow-duration=".70s"
                style={{paddingTop: "188px"}}
              >
                <h3 className="sub_heading mb-3">
                Our  <span>Ethos</span>{" "}
                </h3>
                <p className="sub_para">
                Safety and innovation are the core values of Clarity Codes. We continuously improve our technology to stay ahead of counterfeiters while providing incomparable customer service.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="row" style={{ marginTop: "144px" }}>
            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0 mb-5 mb-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".70s"
                src={hussale_free}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <h3
                className="sub_heading wow fadeInRight"
                data-wow-duration=".70s"
                style={{ marginBottom: "0" }}
              >
                Hassle free system to manage the <span>organisation</span>
              </h3>

              <div className="row wow fadeIn" data-wow-duration="2s">
                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={realtime_tracking} />
                    <h3>Real-time Tracking</h3>
                    <p>
                      Track products in real-time, from production to consumer
                      verification, for immediate response to counterfeit risks.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={repoting} />
                    <h3>Reporting and Analytics</h3>
                    <p>
                      Gain actionable insights with built-in reporting and
                      analytics tools to identify counterfeit trends.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={customization} />
                    <h3>Customization</h3>
                    <p>
                      Tailor the system to match your organization's unique
                      counterfeit solution requirements and processes.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={data_integration} />
                    <h3>Data Integration</h3>
                    <p>
                      Seamlessly incorporate crucial data sources to efficiently
                      enhance the performance of your counterfeit solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "144px" }}>
            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-2 order-lg-1"
              style={{ marginBottom: "0" }}
            >
              <h3
                className="sub_heading wow fadeInLeft"
                data-wow-duration=".70s"
                style={{ marginBottom: "0" }}
              >
                Leading <span>clients</span> towards an automated era
              </h3>
              <p
                className="sub_para wow fadeInLeft"
                data-wow-duration=".70s"
                style={{ marginBottom: "35px" }}
              >
                Our solution integrates cutting-edge technology with best
                practices to provide robust protection for your products.
              </p>

              <div className="row wow fadeIn" data-wow-duration="2s">
                <div className="col-md-6 mb-5 mb-md-0">
                  <div className="d-flex align-items-start">
                    <img alt="icon" src={automated_detection} />
                    <div className="client_automated_inner">
                      <h3>
                        Automated <br /> Detection
                      </h3>
                      <p>
                        Our technology allows for automatic detection of
                        counterfeit products, saving your company time and
                        resources.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-start">
                    <img alt="icon" src={custumer_verification} />
                    <div className="client_automated_inner">
                      <h3>
                        Consumer <br /> Verification
                      </h3>
                      <p>
                        Provide a mobile application that allows consumers to
                        easily verify the authenticity of your products.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0 order-1 order-lg-2 mb-5 mb-lg-0"
              style={{ marginBottom: "0" }}
            >
              <div className="d-flex">
                <img alt="icon"
                  className="sideimg wow fadeInRight"
                  data-wow-duration=".7s"
                  src={client_automated}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".7s"
                src={delivering_success}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet"
                style={{ paddingTop: "173px" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInUp"
                  data-wow-duration=".7s"
                >
                  Delivering Success through <span>Clarity</span> Support
                </h3>
                <p className="sub_para wow fadeInUp" data-wow-duration=".9s">
                  At Clarity Support, we're dedicated to helping your business
                  reach new heights. Our comprehensive support solutions are
                  designed to be your trusted partner in achieving success
                </p>
              </div>
            </div>
          </div> */}


<div className="row our_journey_sec">
            <div className="col-md-12">
              <h2
                className="heading wow fadeIn"
                data-wow-duration="1s"
                style={{ marginTop: "142px", fontSize: "48px", marginBottom: "40px" }}
              >
                Our <span>Journey</span>
              </h2>
            </div>

            <div
              className="col-md-6 offset-0 offset-md-6 position-relative"
              // style={{ marginBottom: "12px" }}
            >
              <div className="d-flex" style={{paddingLeft: "24px", minHeight: "228px"}}>
                <div className="img_box">
                  <img src={journey_1} />
                  <img className="line_img" src={journey_line} />
                </div>
                <div className="text_box wow fadeInUp">
                  <h4>Discovering Counterfeit Cosmetics</h4>
                  <p>With a longstanding history as the world leader in cosmetic bonding and hair glue, <a href="https://www.prohairlabs.com/">Professional Hair Labs</a> discovered our first counterfeit product on a well-known e-commerce platform in 2015.</p>
                  <span className="readmore">Read More</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 position-relative"
              // style={{ marginBottom: "12px" }}
            >
              <div className="d-flex flex-row-reverse">
                <div className="img_box">
                  <img src={journey_2} />
                  <img className="line_img" src={journey_line} />
                </div>
                <div className="text_box wow fadeInUp">
                  <h4>Formulating a Solution to Ensure Safety First</h4>
                  <p>Our company motto - Safety First - was put to the test when one listing quickly turned into thousands. We could not allow our customers to be exposed to the potential.</p>
                  <span className="readmore">Read More</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 offset-0 offset-md-6 position-relative"
              // style={{ marginBottom: "12px" }}
            >
              <div className="d-flex" style={{paddingLeft: "24px", minHeight: "228px"}}>
                <div className="img_box">
                  <img src={journey_3} />
                  <img className="line_img" src={journey_line} />
                </div>
                <div className="text_box wow fadeInUp">
                  <h4>Discovering Counterfeit Cosmetics</h4>
                  <p>With a longstanding history as the world leader in cosmetic bonding and hair glue, Professional Hair Labs discovered our first counterfeit product on a well-known e-commerce platform in 2015.</p>
                  <span className="readmore">Read More</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 position-relative"
              // style={{ marginBottom: "12px" }}
            >
              <div className="d-flex flex-row-reverse">
                <div className="img_box">
                  <img src={journey_4} />
                  <img className="line_img" src={journey_line} />
                </div>
                <div className="text_box wow fadeInUp">
                  <h4>Formulating a Solution to Ensure Safety First</h4>
                  <p>Our company motto - Safety First - was put to the test when one listing quickly turned into thousands. We could not allow our customers to be exposed to the potential.</p>
                  <span className="readmore">Read More</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 offset-0 offset-md-6 position-relative"
              // style={{ marginBottom: "12px" }}
            >
              <div className="d-flex" style={{paddingLeft: "24px", minHeight: "228px"}}>
                <div className="img_box">
                  <img src={journey_5} />
                  <img className="line_img" src={journey_line} />
                </div>
                <div className="text_box wow fadeInUp">
                  <h4>Discovering Counterfeit Cosmetics</h4>
                  <p>With a longstanding history as the world leader in cosmetic bonding and hair glue, Professional Hair Labs discovered our first counterfeit product on a well-known e-commerce platform in 2015.</p>
                  <span className="readmore">Read More</span>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 position-relative"
              // style={{ marginBottom: "12px" }}
            >
              <div className="d-flex flex-row-reverse">
                <div className="img_box">
                  <img src={journey_6} />
                  <img className="line_img" src={journey_line} />
                </div>
                <div className="text_box wow fadeInUp">
                  <h4>Formulating a Solution to Ensure Safety First</h4>
                  <p>Our company motto - Safety First - was put to the test when one listing quickly turned into thousands. We could not allow our customers to be exposed to the potential.</p>
                  <span className="readmore">Read More</span>
                </div>
              </div>
            </div>

            <div className="col-md-12 herosection text-center  wow fadeInUp">
            <Ripples color="#ffffff47" during={1200}>
                <Link
                  to={"/pricing"}
                >
                  <Button variant="" className="subscribeBtn mb-0">
                  Protect your brand today!
                  </Button>
                </Link>
              </Ripples>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <h2
                className="heading wow fadeInUp"
                data-wow-duration=".7s"
                style={{ marginBottom: "20px", marginTop: "160px" }}
              >
                Meet our <span>professionals</span>
              </h2>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 offset-0 offset-md-2 offset-lg-3">
              <p
                className="head_para wow fadeInUp"
                data-wow-duration=".9s"
                style={{ marginBottom: "42px" }}
              >
                Comprehensive Support: Expert Guidance Every Step, Ensuring Your
                Success Through Challenges and Milestones Together.
              </p>
            </div>

            {getProfessionalsData?.length && !isLoading ? (
              getProfessionalsData?.map((Professional) => {
                return (
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div
                      className="customCard_prof wow fadeInUp"
                      data-wow-duration=".7s"
                    >
                      <div className="img_box">
                        <img alt="icon"
                          width="100%"
                          height="190px"
                          style={{ objectFit: "cover" }}
                          src={Professional?.profile}
                        />
                      </div>
                      <div className="content_box">
                        <h2>{Professional?.designation}</h2>
                        <h1>{`${Professional?.firstName} ${Professional?.lastName}`}</h1>
                        <p style={{ wordBreak: "break-all" }}>
                          {Professional?.bio}
                        </p>
                        <ul>
                          <li>
                            <a href={Professional?.linkedInUrl} target="_blank">
                              <img alt="icon" src={linkedin_2} />
                            </a>
                          </li>
                          <li>
                            <a href={Professional?.facebookUrl} target="_blank">
                              <img alt="icon" src={Facebook_2} />
                            </a>
                          </li>
                          <li>
                            <a href={Professional?.intagramUrl} target="_blank">
                              <img alt="icon" src={Instagram_2} />
                            </a>
                          </li>
                          <li>
                            <a href={Professional?.twitterUrl} target="_blank">
                              <img alt="icon" src={twitter_2} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className=""
                style={{
                  height: "100vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
                />
              </div>
            )}

            <div
              className="col-md-12 text-center wow fadeInUp"
              data-wow-duration=".7s"
              style={{ paddingTop: "42px", paddingBottom: "80px" }}
            >
              <Ripples color="#004ac340" during={1200}>
              <Link
                  to={"/careers"}
                >
                  <Button variant="" className="joinus_btn">
                  Join with us
                  </Button>
                </Link>
              </Ripples>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus_wrapper countSection">
        <div className="container">
          {Object.keys(getCountData).length && !isLoadingCount ? (
            <div className="row">
              <div
                className="col-md-12 col-lg-6 mb-3 mb-lg-0"
                style={{ marginBottom: "0" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInUp"
                  data-wow-duration=".7s"
                >
                  Our <span>Count</span>
                </h3>
                <p className="sub_para wow fadeInUp" data-wow-duration=".9s">
                  At Clarity Support, we're dedicated to helping your business
                  reach new heights. Our comprehensive support solutions are
                  designed to be your trusted partner in achieving success
                </p>
              </div>
              <div className="col-md-12 col-lg-6" style={{ marginBottom: "0" }}>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={counterfiet_2} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalCounterFeit} */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalCounterFeit} />
                          <span>+</span>
                        </h2>
                        <p>Counterfeit Reported</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={years} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalYears}  */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalYears} />
                          <span>Years</span>
                        </h2>
                        <p>Of Services</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={client_count} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalClient} */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalClient} />
                          <span>+</span>
                        </h2>
                        <p>Clients</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={user_count} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalUsers} */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalUsers} />
                          <span>+</span>
                        </h2>
                        <p>Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className=""
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default AboutUs;
